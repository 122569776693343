import React from 'react'
import Layout from '../../components/layout'
import Seo from '../../components/seo'

const About = () => {
    return (
        <Layout>
            <Seo title="Nosotros" />
            about page
        </Layout>
    )
}

export default About
